import axios from "axios"

const DEFAULT_TOKEN = {
  "localhost:3000": "dXNlcjokNWEwYWU3N2UzODAxNyQ5NDA1NmYzM2VhYmYzMmUxMjg1N2Y4MWZhNGM4ZWQ0OWQ4NDRmM2Q3NjM3N2I1NzZhODIzMTE1NTM0YjBmYmI4",
  "dora.goodsailors.com": "dXNlcjokNWEwYWU3N2UzODAxNyQ5NDA1NmYzM2VhYmYzMmUxMjg1N2Y4MWZhNGM4ZWQ0OWQ4NDRmM2Q3NjM3N2I1NzZhODIzMTE1NTM0YjBmYmI4",
  "dora2.goodsailors.com": "YWRtaW4yOiQ1ZDMxNmQ4YjMxNGQ0JDc2YmIxMmRiMDhjZTYyMzg4NTY5ZWQyOWIxMjAzOGE5YzVjYjY5OWVjMjc1N2VjYWU2NmQxOTE5ZjkwNjU4Y2Q=",
  "dora3.goodsailors.com": "",
  "dorademo.goodsailors.com": "VEVTVF9VU0VSOiQ2MTAyNjgwY2FiYWJmJGE1MTJjZTJmNGIyZWFiMzczYjU1ZDY4NWFhNGJlOTNhMmM4NjY5M2Y1YTBhNGUzM2Q0MDA2ZDg4NWFhYzQwZTQ=",
}

const api = axios.create({
  baseURL: `${window.location.origin}/api`
})

api.setToken = (token) => api.defaults.headers.common["Authorization"] = `Basic ${token}`

api.setDefaultToken = () => api.setToken(DEFAULT_TOKEN[window.location.host])

export default api
