import {useEffect, useState} from "react"
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import {Badge} from "primereact/badge"
import {Link} from "react-router-dom"
import api from "../../../api"
import {HEADER_STYLES, TASK_STATE_SEVERITY} from "../../common"
import styles from "./styles.module.css"

export default function Tasks() {
  const [tasks, setTasks] = useState([])
  const [total, setTotal] = useState(0)
  const [offset, setOffset] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [type, setType] = useState("/today?")
  const [todayQuantity, setTodayQuantity] = useState(0)
  const [weekQuantity, setWeekQuantity] = useState(0)
  const [expiredQuantity, setExpiredQuantity] = useState(0)
  const [doneQuantity, setDoneQuantity] = useState(0)
  const [notifications, setNotifications] = useState([])
  const auth = JSON.parse(localStorage.getItem("dora.service"))
  const section = auth ? auth.section : ""
  const shift = auth ? auth.shift : ""

  // grid
  const onPage = (event) => setOffset(event.first)

  // grid state column
  const stateBodyTemplate = (rowData) => rowData.state
    ? <Badge severity={TASK_STATE_SEVERITY[rowData.state.id] || ""} value={rowData.state.name}/>
    : ""

  // grid name column
  const nameBodyTemplate = (rowData) => (
    <Link to={`/service/task/${rowData.id}/detail`} className={"p-button p-button-link"}
          data-cy={"detail"}>{rowData.name}</Link>
  )

  useEffect(() => {
    setIsLoading(true)
    api.get(`task${type}limit=50&offset=${offset}&section=${section}&shift=${shift}`)
      .then(({data, headers}) => {
        setTotal(parseInt(headers["x-total-count"], 10))
        setTasks(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [offset, type, section, shift])

  useEffect(() => {
    api.get("notification/today")
      .then(({data}) => setNotifications(data))

    api.get(`task/today?limit=1&section=${section}&shift=${shift}`)
      .then(({headers}) => setTodayQuantity(parseInt(headers["x-total-count"], 10)))
    api.get(`task/week?limit=1&section=${section}&shift=${shift}`)
      .then(({headers}) => setWeekQuantity(parseInt(headers["x-total-count"], 10)))
    api.get(`task/expired?limit=1&section=${section}&shift=${shift}`)
      .then(({headers}) => setExpiredQuantity(parseInt(headers["x-total-count"], 10)))
    api.get(`task?type=2&limit=1&section=${section}&shift=${shift}`)
      .then(({headers}) => setDoneQuantity(parseInt(headers["x-total-count"], 10)))
  }, [section, shift])

  return (
    <>
      <div className={`p-d-inline-block p-text-center p-p-3 ${styles.CardToday}`}
           onClick={() => setType("/today?")}>
        {type.indexOf("today") > -1 && <span>&bull;</span>}
        DNES
        <div className={styles.Quantity}>{todayQuantity}</div>
      </div>
      <div className={`p-d-inline-block p-text-center p-p-3 ${styles.CardWeek}`}
           onClick={() => setType("/week?")}>
        {type.indexOf("week") > -1 && <span>&bull;</span>}
        TENTO TÝDEN
        <div className={styles.Quantity}>{weekQuantity}</div>
      </div>
      <div className={`p-d-inline-block p-text-center p-p-3 ${styles.CardExpired}`}
           onClick={() => setType("/expired?")}>
        {type.indexOf("expired") > -1 && <span>&bull;</span>}
        PO TERMÍNU
        <div className={styles.Quantity}>{expiredQuantity}</div>
      </div>
      <div className={`p-d-inline-block p-text-center p-p-3 ${styles.CardDone}`}
           onClick={() => setType("?state=2&")}>
        {type.indexOf("state=2") > -1 && <span>&bull;</span>}
        DOKONČENO
        <div className={styles.Quantity}>{doneQuantity}</div>
      </div>

      <div className={"p-col-12"}>
        <h3 className={"p-my-0"}>Notifikace:</h3>
        {notifications.length === 0 && "Žádné notifikace pro dnes"}
        {notifications.map(notification => (
          <div className={"p-my-2"} key={notification.id}>{notification.text}</div>
        ))}
      </div>

      <div className={"p-col-12"}>
        <DataTable value={tasks} lazy paginator first={offset} totalRecords={total} rows={50}
                   onPage={onPage} loading={isLoading} emptyMessage={"Nic nenalezeno"}
                   className={"p-datatable-striped p-datatable-sm"}>
          <Column field={"name"} header={"Název"} headerStyle={HEADER_STYLES}
                  body={nameBodyTemplate}/>
          <Column field={"place.name"} header={"Místo"} headerStyle={HEADER_STYLES}/>
          <Column field={"expiredAt"} header={"Termín"} headerStyle={HEADER_STYLES}/>
          <Column field={"state.name"} header={"Stav"} body={stateBodyTemplate}
                  headerStyle={HEADER_STYLES}/>
        </DataTable>
      </div>
    </>
  )
}
