import {useEffect, useState} from "react"
import {InputText} from "primereact/inputtext"
import {InputTextarea} from "primereact/inputtextarea"
import {Button} from "primereact/button"
import api from "../../../api"

export default function Discussions() {
  const [discussions, setDiscussions] = useState([])
  const [title, setTitle] = useState("")
  const [text, setText] = useState("")
  const [answerTitle, setAnswerTitle] = useState("")
  const [answerText, setAnswerText] = useState("")
  const [isUpdated, setIsUpdated] = useState(false)
  const auth = JSON.parse(localStorage.getItem("dora.service"))
  const section = auth ? auth.section : ""
  const shift = auth ? auth.shift : ""

  const handleSubmit = (e) => {
    e.preventDefault()
    let data = {title, text, section, shift}

    if (!data.title) {
      delete data.title
    }

    api.post("discussion", data)
      .then(() => setIsUpdated(true))
  }

  const handleAnswerSubmit = (discussionId) => {
    let data = {
      title: answerTitle,
      text: answerText,
      id: discussionId
    }

    if (!data.title) {
      delete data.title
    }

    api.post(`discussion/${discussionId}/answer`, data)
      .then(() => {
        setIsUpdated(true)
        setAnswerTitle("")
        setAnswerText("")
      })
  }

  const handleDelete = (discussionId) => {
    api.delete(`discussion/${discussionId}`)
      .finally(() => setIsUpdated(true))
  }

  useEffect(() => {
    api.get(`discussion?shift=${shift}&section=${section}`)
      .then(({data}) => setDiscussions(data))
      .finally(() => setIsUpdated(false))
  }, [section, shift, isUpdated])

  return (
    <div className={"p-grid p-p-3 p-jc-center"}>
      <div className={"p-col-12 p-lg-6"}>
        <form onSubmit={handleSubmit} className={"p-fluid p-mb-5"}>
          <div className={"p-field"}>
            <label>Název příspěvku:</label>
            <InputText value={title} onChange={(e) => setTitle(e.target.value)}/>
          </div>
          <div className={"p-field"}>
            <label>*Text příspěvku:</label>
            <InputTextarea value={text} onChange={(e) => setText(e.target.value)} required/>
          </div>
          <Button label={"Přidat příspěvek"} type={"submit"} className={"p-button-success"}/>
        </form>
      </div>

      <div className={"p-col-12 p-lg-9"}>
        {discussions.map(discussion => (
          <div className={"bg-white p-mb-3 p-p-3"} key={discussion.id}>
            <Button icon={"pi pi-times"}
                    className={"p-button-danger p-button-sm p-button-icon-only"}
                    style={{float: "right"}}
                    onClick={() => handleDelete(discussion.id)}/>
            <h3 className={"p-my-2"}>{discussion.title}</h3>
            <div className={"p-my-2"}>{discussion.text}</div>
            {discussion.reactions.map((reaction) => (
              <div className={"p-p-3"} key={reaction.id}>
                <h3 className={"p-m-0"}>{reaction.title}</h3>
                <div>{reaction.text}</div>
              </div>
            ))}
            <form onSubmit={(e) => {
              e.preventDefault()
              handleAnswerSubmit(discussion.id)
            }} className={"p-fluid p-my-2"}>
              <div className={"p-field"}>
                <label>Název odpovědi:</label>
                <InputText value={answerTitle} onChange={(e) => setAnswerTitle(e.target.value)}/>
              </div>
              <div className={"p-field"}>
                <label>*Text odpovědi:</label>
                <InputTextarea value={answerText} onChange={(e) => setAnswerText(e.target.value)}
                               required/>
              </div>
              <Button label={"Odpovědět"} type={"submit"} className={"p-button-success"}/>
            </form>
          </div>
        ))}
      </div>
    </div>
  )
}
