import {useEffect, useState} from "react"
import {useHistory, useParams} from "react-router-dom"
import {InputText} from "primereact/inputtext"
import {InputTextarea} from "primereact/inputtextarea"
import {Button} from "primereact/button"
import {Calendar} from "primereact/calendar"
import {Dropdown} from "primereact/dropdown"
import {RadioButton} from "primereact/radiobutton"
import moment from "moment"
import api from "../../../api"

export default function TaskForm() {
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [expiredAt, setExpiredAt] = useState("")
  const [machines, setMachines] = useState([])
  const [machine, setMachine] = useState("")
  const [repeated, setRepeated] = useState("")
  const [type, setType] = useState("")
  const [shift, setShift] = useState("")
  const [section, setSection] = useState("")
  const [attachments, setAttachments] = useState([])
  //
  const [repeatedOptions, setRepeatedOptions] = useState([])
  const [typeOptions, setTypeOptions] = useState([])
  const [shiftOptions, setShiftOptions] = useState([])
  const [sectionOptions, setSectionOptions] = useState([])

  const history = useHistory()
  const handleBack = () => history.push("/")
  const {id} = useParams()
  const isCopy = window.location.href.indexOf("/copy") > -1
  const handleSubmit = (e) => {
    e.preventDefault()
    let data = {
      name,
      description,
      expiredAt: moment(expiredAt).format("YYYY-MM-DD HH:mm"),
      machine,
      repeated,
      type,
      shift,
      section: [section],
    }

    if (attachments.length) {
      data.attachments = attachments
    }

    if (id && !isCopy) {
      api.put(`task/${id}`, data).then(handleBack)
    } else {
      api.post("task", data).then(handleBack)
    }
  }

  useEffect(() => {
    if (id) {
      api.get(`task/${id}`)
        .then(({data}) => {
          setName(data.name)
          setDescription(data.description)
          setExpiredAt(moment(data.expiredAt).toDate())
          setMachine(data.machine.id)
          setRepeated(data.repeated.id)
          setType(data.type.id)
          setShift(data.shift.id)
          setSection(data.section[0].id)
          setAttachments(data.attachments)
        })
    }

    api.get("machine/simple")
      .then(({data}) => {
        data = data.map(item => Object.assign({}, item, {
          label: item.name,
          value: item.id,
        }))
        setMachines(data)
      })

    api.get("repeated")
      .then(({data}) => setRepeatedOptions(data))

    api.get("taskType")
      .then(({data}) => setTypeOptions(data))

    api.get("shift")
      .then(({data}) => setShiftOptions(data))

    api.get("section")
      .then(({data}) => {
        let sections = []
        data.forEach(section => {
          sections.push(section)
          section.children?.forEach(child => sections.push(child))
        })
        setSectionOptions(sections)
      })
  }, [id])

  const readFile = (e) => {
    e.preventDefault()
    let input = e.target
    if (input.files && input.files[0]) {
      let reader = new FileReader()
      reader.onload = (e) => {
        let isImage = /png|jpg|jpeg|gif/.test(input.files[0].name.toLowerCase())
        let newAttachments = attachments.map(item => Object.assign({}, item))
        newAttachments.push({
          name: input.files[0].name,
          content: btoa(e.target.result),
          is_image: isImage,
          note: "",
        })
        setAttachments(newAttachments)
      }
      reader.readAsDataURL(input.files[0]);
    }
  }

  const handleFileNote = (fileIndex, note) => {
    let newAttachments = attachments.map((attachment, i) => {
      if (i === fileIndex) {
        return Object.assign({}, attachment, {note})
      }
      return Object.assign({}, attachment)
    })
    setAttachments(newAttachments)
  }

  const removeFile = (fileIndex) => {
    let newAttachments = attachments.filter((attachments, i) => i !== fileIndex)
    setAttachments(newAttachments)
  }

  return (
    <div className={"p-col-12 p-lg-5"}>
      <form onSubmit={handleSubmit}>
        <div className={"p-fluid"}>
          <div className={"p-field"}>
            <label htmlFor={"name"}>*Název:</label>
            <InputText value={name} onChange={(e) => setName(e.target.value)} id={"name"} required/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"description"}>*Popis:</label>
            <InputTextarea value={description} onChange={(e) => setDescription(e.target.value)}
                           id={"description"} required/>
            <small>Použijte *tučný text* pro <strong>tučný text</strong>
            </small>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"expiredAt"}>*Termín:</label>
            <Calendar value={expiredAt} onChange={(e) => setExpiredAt(e.value)} id={"expiredAt"}
                      showTime required/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"machine"}>*Stroj:</label>
            <Dropdown value={machine} options={machines} onChange={(e) => setMachine(e.value)}
                      placeholder={"Stroj"} required/>
          </div>

          <label>*Opakování:</label>
          <div className={"p-p-3"}>
            {repeatedOptions.map(repeatedOption => (
              <div className={"p-field-radiobutton"} key={repeatedOption.id}>
                <RadioButton value={repeatedOption.id} name={"repeated"}
                             onChange={() => setRepeated(repeatedOption.id)}
                             checked={repeated === repeatedOption.id}
                             inputId={`rep-${repeatedOption.id}`}
                             required/>
                <label htmlFor={`rep-${repeatedOption.id}`}>{repeatedOption.name}</label>
              </div>
            ))}
          </div>

          <label>*Typ úkolu:</label>
          <div className={"p-p-3"}>
            {typeOptions.map(typeOption => (
              <div className={"p-field-radiobutton"} key={typeOption.id}>
                <RadioButton value={typeOption.id} name={"type"}
                             onChange={() => setType(typeOption.id)}
                             checked={type === typeOption.id} inputId={`typ-${typeOption.id}`}
                             required/>
                <label htmlFor={`typ-${typeOption.id}`}>{typeOption.name}</label>
              </div>
            ))}
          </div>

          <label>Směna:</label>
          <div className={"p-p-3"}>
            {shiftOptions.map(shiftOption => (
              <div className={"p-field-radiobutton"} key={shiftOption.id}>
                <RadioButton value={shiftOption.id} name={"shift"}
                             onChange={() => setShift(shiftOption.id)}
                             checked={shift === shiftOption.id}
                             inputId={`shi-${shiftOption.id}`}/>
                <label htmlFor={`shi-${shiftOption.id}`}>{shiftOption.name}</label>
              </div>
            ))}
          </div>

          <label>*Sekce:</label>
          <div className={"p-p-3"}>
            {sectionOptions.map(sectionOption => (
              <div className={"p-field-radiobutton"} key={sectionOption.id}>
                <RadioButton value={sectionOption.id} name={"section"}
                             onChange={() => setSection(sectionOption.id)}
                             checked={section === sectionOption.id}
                             inputId={`sec-${sectionOption.id}`}
                             required/>
                <label htmlFor={`sec-${sectionOption.id}`}>{sectionOption.name}</label>
              </div>
            ))}
          </div>

          <div className={"p-field"}>
            <label>Přílohy:</label>
            {attachments.map((attachment, i) => (
              <div className={"p-grid"} key={attachment.content}>
                <div className={"p-col-5"}>{attachment.name}</div>
                <div className={"p-col-5"}>
                  <InputText value={attachment.note} placeholder={"Poznámka"}
                             onChange={(e) => handleFileNote(i, e.target.value)}/>
                </div>
                <div className={"p-col-2"}>
                  <Button className={"p-button-danger p-button-icon-only"} icon={"pi pi-times"}
                          type={"button"} onClick={() => removeFile(i)}/>
                </div>
              </div>
            ))}

            <label className={"p-button p-button-primary"}>
              <input type={"file"} id={"file"} className={"p-d-none"} onChange={readFile}/>
              Vložit soubor
            </label>
          </div>
        </div>

        <div className={"p-field"}>
          <Button label={"Zrušit"} type={"button"} className={"p-button-danger p-mr-3"}
                  onClick={handleBack}/>
          <Button label={"Uložit"} type={"submit"} className={"p-button-success p-mr-3"}/>
        </div>
      </form>
    </div>
  )
}
