import {useEffect, useState} from "react"
import {Link, useParams} from "react-router-dom"
import {Badge} from "primereact/badge"
import api from "../../../api"
import {TASK_STATE_SEVERITY} from "../../common"

export default function TaskDetail() {
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [machine, setMachine] = useState("")
  const [place, setPlace] = useState("")
  const [attachments, setAttachments] = useState([])
  const [state, setState] = useState({})
  const [expiredAt, setExpiredAt] = useState("")
  const {id} = useParams()

  useEffect(() => {
    api.get(`task/${id}`)
      .then(({data}) => {
        setName(data.name)
        setDescription(data.description)
        setMachine(data.machine)
        setPlace(data.place)
        setAttachments(data.attachments)
        setState(data.state)
        setExpiredAt(data.expiredAt)
      })
  }, [id])

  return (
    <div className={"p-grid p-p-3 p-jc-center"}>
      <div className={"p-col-12 p-lg-6 bg-white"}>
        <div className={"p-field"}>
          <strong>Název:</strong> <span>{name}</span>
        </div>

        <div className={"p-field"}>
          <strong>Stav:</strong> <Badge severity={TASK_STATE_SEVERITY[state.id]}
                                        value={state.name}/>
        </div>

        <div className={"p-field"}>
          <strong>Popis:</strong> <span>{description}</span>
        </div>

        <div className={"p-field"}>
          <strong>Termín dokončení:</strong> <span>{expiredAt}</span>
        </div>

        <div className={"p-field"}>
          <strong>Místo:</strong> <span>{place.name}</span>
        </div>

        <div className={"p-field"}>
          <strong>Stroj:</strong> <span>{machine.name}</span>
        </div>

        <div className={"p-field"}>
          <strong>Přílohy:</strong>
          <ul>
            {attachments.map((attachment) => (
              <li key={attachment.content}>
                <a href={attachment.url} target={"_blank"} rel={"noreferrer"}>{attachment.name}</a>
                {attachment.note && (<span> - {attachment.note}</span>)}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={"p-col-12 p-lg-3 p-fluid"}>
        {state.id !== 2 && state.id !== 3 && (
          <>
            <Link to={`/service/task/${id}/complete`}
                  className={"p-button p-button-success p-button-lg p-mb-3"}>Dokočit</Link>
            <Link to={`/service/task/${id}/postpone`} className={"p-button p-button-danger"}>Nelze
              dokončit</Link>
          </>
        )}
      </div>
    </div>
  )
}
