import {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import api from "../../../api"

export default function OrderDetail() {
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [machine, setMachine] = useState("")
  const [attachments, setAttachments] = useState([])
  const {id} = useParams()

  useEffect(() => {
    api.get(`order/${id}`)
      .then(({data}) => {
        setTitle(data.title)
        setDescription(data.description)
        setMachine(data.machine)
        setAttachments(data.attachments)
      })
  }, [id])

  return (
    <div className={"p-grid p-p-3 p-jc-center"}>
      <div className={"p-col-12 p-lg-9 bg-white"}>
        <div className={"p-field"}>
          <strong>Název:</strong> <span>{title}</span>
        </div>

        <div className={"p-field"}>
          <strong>Popis:</strong> <span>{description}</span>
        </div>

        <div className={"p-field"}>
          <strong>Stroj:</strong> <span>{machine.place?.name}</span>
        </div>

        <div className={"p-field"}>
          <strong>Stroj:</strong> <span>{machine.name}</span>
        </div>

        <div className={"p-field"}>
          <strong>Přílohy:</strong>
          <ul>
            {attachments.map((attachment) => (
              <li key={attachment.content}>
                <a href={attachment.url} target={"_blank"} rel={"noreferrer"}>{attachment.name}</a>
                {attachment.note && (<span> - {attachment.note}</span>)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
