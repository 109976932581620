import {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import {Badge} from "primereact/badge"
import api from "../../../api"
import {HEADER_STYLES, ORDER_STATE_SEVERITY} from "../../common"

export default function Orders() {
  const [tasks, setTasks] = useState([])
  const [total, setTotal] = useState(0)
  const [offset, setOffset] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  // grid
  const onPage = (event) => setOffset(event.first)

  // grid name column
  const titleBodyTemplate = (rowData) => (
    <Link to={`order/${rowData.id}/detail`}
          className={"p-button p-button-link"}>{rowData.title}</Link>
  )

  // grid state column
  const stateBodyTemplate = (rowData) => rowData.state
    ? <Badge severity={ORDER_STATE_SEVERITY[rowData.state.id] || ""} value={rowData.state.name}/>
    : ""

  useEffect(() => {
    setIsLoading(true)
    api.get(`order?limit=50&offset=${offset}`)
      .then(({data, headers}) => {
        setTotal(parseInt(headers["x-total-count"], 10))
        setTasks(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [offset])

  return (
    <div className={"p-col-12"}>
      <DataTable value={tasks} lazy paginator first={offset} totalRecords={total} rows={50}
                 onPage={onPage} loading={isLoading} emptyMessage={"Nic nenalezeno"}
                 className={"p-datatable-striped p-datatable-sm"}>
        <Column field={"title"} header={"Název"} headerStyle={HEADER_STYLES}
                body={titleBodyTemplate}/>
        <Column field={"description"} header={"Popis"} headerStyle={HEADER_STYLES}/>
        <Column field={"machine.name"} header={"Stroj"} headerStyle={HEADER_STYLES}/>
        <Column field={"state.name"} header={"Stav"} headerStyle={HEADER_STYLES}
                body={stateBodyTemplate}/>
      </DataTable>
    </div>
  )
}
