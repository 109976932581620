const HEADER_STYLES = {
  backgroundColor: "#A6ACBA",
  color: "#FFFFFF",
}

const TASK_STATE = [{
  label: "Nesplněné",
  value: 1
}, {
  label: "Splněné",
  value: 2
}, {
  label: "S chybou",
  value: 3
}]

const TASK_TYPE = [{
  label: "Údržba",
  value: 1
}, {
  label: "Provoz",
  value: 2
}, {
  label: "Prevence",
  value: 3
},]

const TASK_STATE_SEVERITY = {
  1: "warning",
  2: "success",
  3: "danger",
}

const ORDER_STATE_SEVERITY = {
  1: "",
  2: "",
  3: "success"
}

const REPAIR_STATE_SEVERITY = {
  1: "success",
  2: "danger",
  3: "warning",
}

const ATTACHMENT_TYPE = [{
  value: 1,
  label: "Obecná příloha",
}, {
  value: 2,
  label: "Obrázek",
}, {
  value: 3,
  label: "SOP",
},]

export {
  HEADER_STYLES,
  TASK_STATE,
  TASK_TYPE,
  TASK_STATE_SEVERITY,
  ORDER_STATE_SEVERITY,
  REPAIR_STATE_SEVERITY,
  ATTACHMENT_TYPE,
}
