import {useEffect, useState} from "react"
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import api from "../../../api"
import {HEADER_STYLES} from "../../common"

export default function Notifications() {
  const [notifications, setNotifications] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    api.get("notification/today")
      .then(({data}) => setNotifications(data))
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <div className={"p-col-12"}>
      <DataTable value={notifications} lazy loading={isLoading}
                 className={"p-datatable-striped p-datatable-sm"} emptyMessage={"Nic nenalezeno"}>
        <Column field={"text"} header={"Text"} headerStyle={HEADER_STYLES}/>
        <Column field={"startsAt"} header={"Od"} headerStyle={HEADER_STYLES}/>
        <Column field={"endsAt"} header={"Do"} headerStyle={HEADER_STYLES}/>
      </DataTable>
    </div>
  )
}
