import {useEffect, useState} from "react"
import {useHistory, useParams} from "react-router-dom"
import {InputText} from "primereact/inputtext"
import {InputTextarea} from "primereact/inputtextarea"
import {Calendar} from "primereact/calendar"
import {Button} from "primereact/button"
import {Dropdown} from "primereact/dropdown"
import api from "../../../api"
import moment from "moment"

export default function MaintenanceForm() {
  const [title, setTitle] = useState("")
  const [activity, setActivity] = useState("")
  const [type, setType] = useState("")
  const [timeFrom, setTimeFrom] = useState("")
  const [timeTo, setTimeTo] = useState("")
  const [machine, setMachine] = useState("")
  const [machines, setMachines] = useState([])
  const [attachments, setAttachments] = useState([])
  const history = useHistory()
  const handleBack = () => history.push("/service/maintenances")
  const {id} = useParams()
  const auth = JSON.parse(localStorage.getItem("dora.service"))
  const section = auth ? auth.section : ""
  const shift = auth ? auth.shift : ""
  const isCopy = window.location.href.indexOf("/copy") > -1

  const handleSubmit = (e) => {
    e.preventDefault()
    let data = {
      title,
      activity,
      type,
      timeFrom: moment(timeFrom).format("HH:mm:ss"),
      timeTo: moment(timeTo).format("HH:mm:ss"),
      machine,
      attachments,
      section,
      shift,
    }

    if (attachments.length === 0) {
      delete data.attachments
    }

    if (id && !isCopy) {
      api.put(`maintenanceBook/${id}`, data).then(handleBack)
    } else {
      api.post("maintenanceBook", data).then(handleBack)
    }
  }

  const types = [{
    label: "Údržba",
    value: 1
  }, {
    label: "Oprava  ",
    value: 2
  }]

  const readFile = (e) => {
    e.preventDefault()
    let input = e.target
    if (input.files && input.files[0]) {
      let reader = new FileReader()
      reader.onload = (e) => {
        let isImage = /png|jpg|jpeg|gif/.test(input.files[0].name.toLowerCase())
        let newAttachments = attachments.map(item => Object.assign({}, item))
        newAttachments.push({
          name: input.files[0].name,
          content: btoa(e.target.result),
          is_image: isImage,
          note: "",
        })
        setAttachments(newAttachments)
      }
      reader.readAsDataURL(input.files[0]);
    }
  }

  const removeFile = (fileIndex) => {
    let newAttachments = attachments.filter((attachments, i) => i !== fileIndex)
    setAttachments(newAttachments)
  }

  useEffect(() => {
    if (id) {
      api.get(`maintenanceBook/${id}`)
        .then(({data}) => {
          setTitle(data.title)
          setActivity(data.activity)
          setType(data.type.id)
          setTimeFrom(moment(data.timeFrom, "HH:mm:ss").toDate())
          setTimeTo(moment(data.timeTo, "HH:mm:ss").toDate())
          setMachine(parseInt(data.machine.id, 10))
          setAttachments(data.attachments)
        })
    }

    api.get("machine/simple")
      .then(({data}) => {
        data = data.map(item => Object.assign({}, item, {
          label: item.name,
          value: item.id,
        }))
        setMachines(data)
      })
  }, [id])

  return (
    <div className={"p-lg-5"}>
      <form onSubmit={handleSubmit}>
        <div className={"p-fluid"}>
          <div className={"p-field"}>
            <label htmlFor={"title"}>*Název:</label>
            <InputText value={title} onChange={(e) => setTitle(e.target.value)} required
                       id={"title"}/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"activity"}>*Popis:</label>
            <InputTextarea rows={5} cols={50} onChange={(e) => setActivity(e.target.value)}
                           value={activity}
                           id={"activity"} required/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"type"}>*Typ:</label>
            <Dropdown value={type} options={types} onChange={(e) => setType(e.target.value)}/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"timeFrom"}>*Od:</label>
            <Calendar value={timeFrom} onChange={(e) => setTimeFrom(e.value)} id={"timeFrom"}
                      showTime timeOnly required/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"timeTo"}>*Do:</label>
            <Calendar value={timeTo} onChange={(e) => setTimeTo(e.value)} id={"timeTo"} showTime
                      timeOnly required/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"machine"}>*Stroj:</label>
            <Dropdown value={machine} options={machines}
                      onChange={(e) => setMachine(e.target.value)}/>
          </div>

          <div className={"p-field"}>
            <label>Přílohy:</label>
            {attachments.map((attachment, i) => (
              <div className={"p-grid"} key={attachment.content}>
                <div className={"p-col-8"}>{attachment.name}</div>
                <div className={"p-col-4"}>
                  <Button className={"p-button-danger p-button-icon-only"} icon={"pi pi-times"}
                          type={"button"} onClick={() => removeFile(i)}/>
                </div>
              </div>
            ))}

            <label className={"p-button p-button-primary"}>
              <input type={"file"} id={"file"} className={"p-d-none"} onChange={readFile}/>
              Vložit soubor
            </label>
          </div>
        </div>

        <div className={"p-field"}>
          <Button label={"Zrušit"} type={"button"} className={"p-button-danger p-mr-3"}
                  onClick={handleBack}/>
          <Button label={"Uložit"} type={"submit"} className={"p-button-success p-mr-3"}/>
        </div>
      </form>
    </div>
  )
}
