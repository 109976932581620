import React from "react"
import ReactDOM from "react-dom"
import {BrowserRouter} from "react-router-dom"
import AdminApp from "./AdminApp"
import ServiceApp from "./ServiceApp"

import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "primeflex/primeflex.css"
import "primereact/resources/primereact.min.css"
import "primereact/resources/themes/bootstrap4-light-blue/theme.css"
import "primeicons/primeicons.css"
import "./component/global.css"

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      {window.location.href.indexOf("/service") > -1
        ? <ServiceApp/>
        : <AdminApp/>
      }
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
)
