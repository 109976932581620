import {useState} from "react"
import {Redirect, Route, Switch, useHistory} from "react-router-dom"
import api from "./api"
import Login from "./component/Service/Login/Login"
import Nav from "./component/Service/Nav/Nav"
import Tasks from "./component/Service/Tasks/Tasks"
import TaskDetail from "./component/Service/Tasks/TaskDetail"
import TaskComplete from "./component/Service/Tasks/TaskComplete"
import Notifications from "./component/Service/Notifications/Notifications"
import Discussions from "./component/Service/Discussions/Discussions"
import Repairs from "./component/Service/Repairs/Repairs"
import RepairForm from "./component/Service/Repairs/RepairForm"
import Maintenances from "./component/Service/Maintenances/Maintenances"
import MaintenanceForm from "./component/Service/Maintenances/MaintenanceForm"
import MaintenanceDetail from "./component/Service/Maintenances/MaintenanceDetail"
import Orders from "./component/Service/Orders/Orders"
import OrderDetail from "./component/Service/Orders/OrderDetail"
import OrderForm from "./component/Service/Orders/OrderForm"
import TaskPostpone from "./component/Service/Tasks/TaskPostpone"
import Machines from "./component/Service/Machines/Machines"
import MachineDetail from "./component/Service/Machines/MachineDetail"

export default function ServiceApp() {
  let history = useHistory()
  let auth = JSON.parse(localStorage.getItem("dora.service"))
  let [isLoggedIn, setIsLoggedIn] = useState(!!auth)

  api.setDefaultToken()

  if (auth) {
    api.setToken(auth.token)
  } else {
    history.push("/service/login")
  }

  const logInHandler = (loginData) => {
    let {section, shift} = loginData
    delete loginData.section
    delete loginData.shift

    api.post("auth", loginData)
      .then(({data}) => {
        data.section = section
        data.shift = shift
        localStorage.setItem("dora.service", JSON.stringify(data))
        api.setToken(data.token)
        history.push("/service")
        setIsLoggedIn(true)
      }).catch(() => alert("Špatná kombinace jména a hesla"))
  }
  const logOutHandler = () => {
    localStorage.removeItem("dora.service")
    api.setToken("")
    history.push("/service/login")
    setIsLoggedIn(false)
  }

  return (
    <>
      {isLoggedIn && (
        <Nav auth={auth} logOutHandler={logOutHandler}/>
      )}

      <Switch>
        <Route path={"/service"} exact>
          {isLoggedIn && <Redirect to={"/service/tasks"}/>}
        </Route>
        <Route path={"/service/login"} exact>
          <Login logInHandler={logInHandler}/>
        </Route>
        <Route path={"/service/task/:id/detail"} exact>
          <TaskDetail/>
        </Route>
        <Route path={"/service/task/:id/complete"} exact>
          <TaskComplete/>
        </Route>
        <Route path={"/service/task/:id/postpone"} exact>
          <TaskPostpone/>
        </Route>
        <Route path={"/service/tasks"} exact>
          <Tasks/>
        </Route>
        <Route path={"/service/maintenance/:id/detail"} exact>
          <MaintenanceDetail/>
        </Route>
        <Route path={"/service/maintenance/:id"} exact>
          <MaintenanceForm/>
        </Route>
        <Route path={"/service/maintenance/:id/copy"} exact>
          <MaintenanceForm/>
        </Route>
        <Route path={"/service/maintenance"} exact>
          <MaintenanceForm/>
        </Route>
        <Route path={"/service/maintenances"} exact>
          <Maintenances/>
        </Route>
        <Route path={"/service/order"} exact>
          <OrderForm/>
        </Route>
        <Route path={"/service/order/:id/detail"} exact>
          <OrderDetail/>
        </Route>
        <Route path={"/service/orders"} exact>
          <Orders/>
        </Route>
        <Route path={"/service/notifications"} exact>
          <Notifications/>
        </Route>
        <Route path={"/service/machines"} exact>
          <Machines/>
        </Route>
        <Route path={"/service/machine/:id/detail"} exact>
          <MachineDetail/>
        </Route>
        <Route path={"/service/discussions"} exact>
          <Discussions/>
        </Route>
        <Route path={"/service/repair/:id"} exact>
          <RepairForm/>
        </Route>
        <Route path={"/service/repair"} exact>
          <RepairForm/>
        </Route>
        <Route path={"/service/repairs"} exact>
          <Repairs/>
        </Route>
      </Switch>
    </>
  )
}
