import {useEffect, useState} from "react"
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import {Dropdown} from "primereact/dropdown"
import {Button} from "primereact/button"
import {Link, useHistory} from "react-router-dom"
import api from "../../../api"
import {HEADER_STYLES} from "../../common"
import {serialize} from "../../utils"

export default function Maintenances() {
  const [tasks, setTasks] = useState([])
  const [total, setTotal] = useState(0)
  const [offset, setOffset] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const auth = JSON.parse(localStorage.getItem("dora.service"))
  const section = auth ? auth.section : ""
  //const shift = auth ? auth.shift : ""
  const [query, setQuery] = useState("")
  const history = useHistory()

  // grid
  const onPage = (event) => setOffset(event.first)
  const onFilter = (event) => event.filters.title
    ? setQuery(event.filters.title.value)
    : setQuery("")

  // grid name column
  const titleBodyTemplate = (rowData) => (
    <Link to={`/service/maintenance/${rowData.id}/detail`}
          className={"p-button p-button-link"}>{rowData.title}</Link>
  )

  // grid machine column
  const [machines, setMachines] = useState([])
  const [machine, setMachine] = useState("")
  const onMachineChange = (e) => setMachine(e.value || "")
  const machineItemTemplate = (option) => option.label
  const machineFilter = (
    <Dropdown value={machine} options={machines} onChange={onMachineChange}
              itemTemplate={machineItemTemplate} placeholder={"Filtrovat dle stroje"}
              className={"p-column-filter"} showClear/>
  )

  // grid machine column
  const machineBodyTemplate = (rowData) => (
    <span>{rowData.machine.name} {rowData.machine.place.name}</span>
  )

  // grid control column
  const handleEdit = (id) => {
    history.push(`/service/maintenance/${id}`)
  }
  const handleCopy = (id) => {
    history.push(`/service/maintenance/${id}/copy`)
  }
  const handleDelete = (id) => {
    api.delete(`maintenanceBook/${id}`)
      .then(() => setIsUpdated(true))
  }
  const controlBodyTemplate = (rowData) => (
    <>
      <Button className={"p-button-primary p-button-text p-button-sm"}
              icon={"pi pi-pencil"} title={"Upravit"} onClick={() => handleEdit(rowData.id)}/>
      <Button className={"p-button-success p-button-text p-button-sm"}
              icon={"pi pi-copy"} title={"Kopírovat"} onClick={() => handleCopy(rowData.id)}/>
      <Button className={"p-button-danger p-button-text p-button-sm"}
              icon={"pi pi-times"} title={"Smazat"} onClick={() => handleDelete(rowData.id)}/>
    </>
  )

  useEffect(() => {
    setIsLoading(true)
    let params = {
      order: "date",
      direction: "DESC",
      limit: 50,
      section: section,
      offset: offset,
      query: query,
      machine: machine,
    }
    api.get(`maintenanceBook?${serialize(params)}`)
      .then(({data, headers}) => {
        setTotal(parseInt(headers["x-total-count"], 10))
        setTasks(data)
      })
      .finally(() => {
        setIsLoading(false)
        setIsUpdated(false)
      })
  }, [section, offset, query, machine, isUpdated])

  useEffect(() => {
    api.get("machine/simple")
      .then(({data}) => {
        data = data.map(machine => Object.assign({}, machine, {
          label: machine.name,
          value: machine.id
        }))
        setMachines(data)
      })
  }, [])

  return (
    <>
      <div className={"p-col-12"}>
        <Link to={"/service/maintenance"} className={"p-button p-button-success"}>Přidat
          zápis</Link>
        <Link to={"/service/orders"} className={"p-button p-button-link"}>Přejít na
          objednávky</Link>
      </div>
      <div className={"p-col-12"}>
        <DataTable value={tasks} lazy paginator first={offset} totalRecords={total} rows={50}
                   onPage={onPage} onFilter={onFilter} loading={isLoading}
                   className={"p-datatable-striped p-datatable-sm"} emptyMessage={"Nic nenalezeno"}>
          <Column filter filterPlaceholder={"Filtrovat dle názvu"} field={"title"}
                  header={"Název"} headerStyle={HEADER_STYLES}
                  body={titleBodyTemplate}/>
          <Column field={"createdAt"} header={"Vytvořeno"} headerStyle={HEADER_STYLES}/>
          <Column field={"machine"} header={"Stroj"} headerStyle={HEADER_STYLES} filter
                  filterElement={machineFilter} body={machineBodyTemplate}/>
          <Column field={"section.name"} header={"sekce"} headerStyle={HEADER_STYLES}/>
          <Column field={"control"} header={""} headerStyle={HEADER_STYLES}
                  body={controlBodyTemplate}/>
        </DataTable>
      </div>
    </>
  )
}
