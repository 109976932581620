import {useEffect, useState} from "react"
import {useHistory, useParams} from "react-router-dom"
import {InputTextarea} from "primereact/inputtextarea"
import {Calendar} from "primereact/calendar"
import {Button} from "primereact/button"
import moment from "moment"
import api from "../../../api"

export default function NotificationForm() {
  const [text, setText] = useState("")
  const [startsAt, setStartsAt] = useState("")
  const [endsAt, setEndsAt] = useState("")
  const history = useHistory()
  const handleBack = () => history.push("/notifications")
  const {id} = useParams()
  const handleSubmit = (e) => {
    e.preventDefault()
    let data = {
      text,
      startsAt: moment(startsAt).format("YYYY-MM-DD HH:mm"),
      endsAt: moment(endsAt).format("YYYY-MM-DD HH:mm"),
    }

    if (id) {
      api.put(`notification/${id}`, data).then(handleBack)
    } else {
      api.post("notification", data).then(handleBack)
    }
  }

  useEffect(() => {
    if (id) {
      api.get(`notification/${id}`)
        .then(({data}) => {
          setText(data.text)
          setStartsAt(moment(data.startsAt).toDate())
          setEndsAt(moment(data.endsAt).toDate())
        })
    }
  }, [id])

  return (
    <div className={"p-lg-5"}>
      <form onSubmit={handleSubmit}>
        <div className={"p-fluid"}>
          <div className={"p-field"}>
            <label htmlFor={"text"}>Text:</label>
            <InputTextarea rows={5} cols={50} onChange={(e) => setText(e.target.value)} value={text}
                           id={"text"} required/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"startsAt"}>Od:</label>
            <Calendar value={startsAt} onChange={(e) => setStartsAt(e.value)} id={"startsAt"}
                      showTime required/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"endsAt"}>Do:</label>
            <Calendar value={endsAt} onChange={(e) => setEndsAt(e.value)} id={"endsAt"} showTime
                      required/>
          </div>
        </div>

        <div className={"p-field"}>
          <Button label={"Zrušit"} type={"button"} className={"p-button-danger p-mr-3"}
                  onClick={handleBack}/>
          <Button label={"Uložit"} type={"submit"} className={"p-button-success p-mr-3"}/>
        </div>
      </form>
    </div>
  )
}
