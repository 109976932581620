import {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import api from "../../../api"
import {HEADER_STYLES} from "../../common"

export default function Machines() {
  const [machines, setMachines] = useState([])
  const [total, setTotal] = useState(0)
  const [offset, setOffset] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  // grid
  const onPage = (event) => setOffset(event.first)

  // grid name column
  const nameBodyTemplate = (rowData) => (
    <Link to={`/service/machine/${rowData.id}/detail`}
          className={"p-button p-button-link"}>{rowData.name}</Link>
  )

  useEffect(() => {
    setIsLoading(true)
    api.get(`machine?limit=50&offset=${offset}`)
      .then(({data, headers}) => {
        setTotal(parseInt(headers["x-total-count"], 10))
        setMachines(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [offset])

  return (
    <div className={"p-col-12"}>
      <DataTable value={machines} lazy paginator first={offset} totalRecords={total} rows={50}
                 onPage={onPage} loading={isLoading} emptyMessage={"Nic nenalezeno"}
                 className={"p-datatable-striped p-datatable-sm"}>
        <Column field={"name"} header={"Název"} headerStyle={HEADER_STYLES}
                body={nameBodyTemplate}/>
        <Column field={"place.name"} header={"Místo"} headerStyle={HEADER_STYLES}/>
      </DataTable>
    </div>
  )
}
