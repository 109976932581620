import {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import api from "../../../api"

export default function TaskDetail() {
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [expiredAt, setExpiredAt] = useState("")
  const [machine, setMachine] = useState("")
  const [repeated, setRepeated] = useState("")
  const [type, setType] = useState("")
  const [shift, setShift] = useState("")
  const [section, setSection] = useState("")
  const [attachments, setAttachments] = useState([])
  const [result, setResult] = useState({})
  const [state, setState] = useState({})
  const {id} = useParams()

  useEffect(() => {
    api.get(`task/${id}`)
      .then(({data}) => {
        setName(data.name)
        setDescription(data.description)
        setExpiredAt(data.expiredAt)
        setMachine(data.machine.name)
        setRepeated(data.repeated.name)
        setType(data.type.name)
        setShift(data.shift.name)
        setSection(data.section[0].name)
        setAttachments(data.attachments)
        setResult(data.result)
        setState(data.state)
      })
  }, [id])

  return (
    <div className={"p-grid p-p-3 p-jc-center"}>
      <div className={"p-col-12 p-lg-9 bg-white"}>
        <div className={"p-field"}>
          <strong>Název:</strong> <span>{name}</span>
        </div>

        <div className={"p-field"}>
          <strong>Popis:</strong> <span>{description}</span>
        </div>

        <div className={"p-field"}>
          <strong>Termín:</strong> <span>{expiredAt}</span>
        </div>

        <div className={"p-field"}>
          <strong>Stroj:</strong> <span>{machine}</span>
        </div>

        <div className={"p-field"}>
          <strong>Opakování:</strong> <span>{repeated}</span>
        </div>

        <div className={"p-field"}>
          <strong>Typ úkolu:</strong> <span>{type}</span>
        </div>

        <div className={"p-field"}>
          <strong>Směna:</strong> <span>{shift}</span>
        </div>

        <div className={"p-field"}>
          <strong>Sekce:</strong> <span>{section}</span>
        </div>

        <div className={"p-field"}>
          <strong>Přílohy:</strong>
          <ul>
            {attachments.map((attachment) => (
              <li key={attachment.content}>
                <a href={attachment.url} target={"_blank"} rel={"noreferrer"}>{attachment.name}</a>
                {attachment.note && (<span> - {attachment.note}</span>)}
              </li>
            ))}
          </ul>
        </div>

        {state.id !== 1 && (
          <>
            <div className={"p-field"}>
              <strong>Poznámka k dokončení:</strong> <span>{result.note}</span>
            </div>
            <div className={"p-field"}>
              <strong>Dokončeno od:</strong> <span>{result.dateFrom} {result.timeFrom}</span>
            </div>
            <div className={"p-field"}>
              <strong>Dokončeno do:</strong> <span>{result.dateTo} {result.timeTo}</span>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
