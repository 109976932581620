import {useEffect, useState} from "react"
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import {Dropdown} from "primereact/dropdown"
import {Badge} from "primereact/badge"
import {Button} from "primereact/button"
import {Link, useHistory} from "react-router-dom"
import api from "../../../api"
import {HEADER_STYLES, TASK_STATE, TASK_STATE_SEVERITY, TASK_TYPE} from "../../common"
import styles from "./styles.module.css"
import {serialize} from "../../utils"

export default function Tasks() {
  const [tasks, setTasks] = useState([])
  const [total, setTotal] = useState(0)
  const [offset, setOffset] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [query, setQuery] = useState("")
  const history = useHistory()

  // grid
  const onPage = (event) => setOffset(event.first)
  const onFilter = (event) => event.filters.name
    ? setQuery(event.filters.name.value)
    : setQuery("")

  // grid name column

  // grid place column
  const [machines, setMachines] = useState([])
  const [machine, setMachine] = useState("")
  const onMachineChange = (e) => setMachine(e.value || "")
  const machineItemTemplate = (option) => option.label
  const machineFilter = (
    <Dropdown value={machine} options={machines} onChange={onMachineChange}
              itemTemplate={machineItemTemplate} placeholder={"Filtrovat dle stroje"}
              className={"p-column-filter"} showClear/>
  )

  // grid state column
  const [state, setState] = useState("")
  const onStateChange = (e) => setState(e.value || "")
  const stateItemTemplate = (option) => option.label
  const stateFilter = (
    <Dropdown value={state} options={TASK_STATE} onChange={onStateChange}
              itemTemplate={stateItemTemplate} placeholder={"Filtrovat dle stavu"}
              className={"p-column-filter"} showClear/>
  )

  const stateBodyTemplate = (rowData) => rowData.state
    ? <Badge severity={TASK_STATE_SEVERITY[rowData.state.id] || ""} value={rowData.state.name}/>
    : ""

  // grid name column
  const nameBodyTemplate = (rowData) => (
    <Link to={`task/${rowData.id}/detail`} className={"p-button p-button-link"}
          data-cy={"detail"}>{rowData.name}</Link>
  )

  // grid type column
  const [type, setType] = useState("")
  const onTypeChange = (e) => setType(e.value || "")
  const typeItemTemplate = (option) => option.label
  const typeFilter = (
    <Dropdown value={type} options={TASK_TYPE} onChange={onTypeChange}
              itemTemplate={typeItemTemplate} placeholder={"Filtrovat dle sekce"}
              className={"p-column-filter"} showClear/>
  )

  // grid control column
  const handleEdit = (id) => {
    history.push(`task/${id}`)
  }
  const handleCopy = (id) => {
    history.push(`task/${id}/copy`)
  }
  const handleDelete = (id) => {
    api.delete(`task/${id}`)
      .then(() => setIsUpdated(true))
  }
  const controlBodyTemplate = (rowData) => (
    <>
      <Button className={"p-button-danger p-button-icon-only p-button-text p-button-sm"}
              icon={"pi pi-times"} title={"Smazat"} onClick={() => handleDelete(rowData.id)}/>
      <Button className={"p-button-primary p-button-icon-only p-button-text p-button-sm"}
              icon={"pi pi-pencil"} title={"Upravit"} onClick={() => handleEdit(rowData.id)}/>
      <Button className={"p-button-success p-button-icon-only p-button-text p-button-sm"}
              icon={"pi pi-copy"} title={"Kopírovat"} onClick={() => handleCopy(rowData.id)}/>
    </>
  )

  useEffect(() => {
    setIsLoading(true)
    let params = {
      order: "expired",
      direction: "DESC",
      limit: 50,
      offset: offset,
      state: state,
      query: query,
      machine: machine,
      type: type,
    }
    api.get(`task?${serialize(params)}`)
      .then(({data, headers}) => {
        setTotal(parseInt(headers["x-total-count"], 10))
        setTasks(data)
      })
      .finally(() => {
        setIsLoading(false)
        setIsUpdated(false)
      })
  }, [offset, state, query, machine, isUpdated, type])

  useEffect(() => {
    api.get("machine/simple")
      .then(({data}) => {
        data = data.map(machine => Object.assign({}, machine, {
          label: machine.name,
          value: machine.id
        }))
        setMachines(data)
      })
  }, [])

  return (
    <>
      <div>
        <div className={`p-d-inline-block p-text-center p-p-3 ${styles.CardAll}`}
             onClick={() => setState("")}>
          {state === "" && <span>&bull;</span>}
          Všechny
        </div>
        <div className={`p-d-inline-block p-text-center p-p-3 ${styles.CardDone}`}
             onClick={() => setState(2)}>
          {state === 2 && <span>&bull;</span>}
          Splněné
        </div>
        <div className={`p-d-inline-block p-text-center p-p-3 ${styles.CardPending}`}
             onClick={() => setState(1)}>
          {state === 1 && <span>&bull;</span>}
          Nesplněné
        </div>
        <div className={`p-d-inline-block p-text-center p-p-3 ${styles.CardError}`}
             onClick={() => setState(3)}>
          {state === 3 && <span>&bull;</span>}
          S chybou
        </div>
      </div>
      <div className={"p-col-12"}>
        <Link to={"/task"} className={"p-button p-button-success"} data-cy={"form"}>Nový úkol</Link>
      </div>
      <div className={"p-col-12"}>
        <DataTable value={tasks} lazy paginator first={offset} totalRecords={total} rows={50}
                   onPage={onPage} onFilter={onFilter} loading={isLoading}
                   className={"p-datatable-striped p-datatable-sm"} emptyMessage={"Nic nenalezeno"}>
          <Column field={"name"} header={"Název"} filter filterPlaceholder={"Název"}
                  headerStyle={HEADER_STYLES} body={nameBodyTemplate}/>
          <Column field={"place.name"} header={"Místo"} filter filterElement={machineFilter}
                  headerStyle={HEADER_STYLES}/>
          <Column field={"type.name"} header={"Sekce"} filter filterElement={typeFilter}
                  headerStyle={HEADER_STYLES}/>
          <Column field={"expiredAt"} header={"Termín"} headerStyle={HEADER_STYLES}/>
          <Column field={"state.name"} header={"Stav"} filter filterElement={stateFilter}
                  body={stateBodyTemplate} headerStyle={HEADER_STYLES}/>
          <Column field={"control"} header={""} headerStyle={HEADER_STYLES}
                  body={controlBodyTemplate}/>
        </DataTable>
      </div>
    </>
  )
}
