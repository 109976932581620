import {useEffect, useState} from "react"
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import {Badge} from "primereact/badge"
import {Dropdown} from "primereact/dropdown"
import {Button} from "primereact/button"
import {Link, useHistory} from "react-router-dom"
import api from "../../../api"
import {HEADER_STYLES, REPAIR_STATE_SEVERITY} from "../../common"
import {serialize} from "../../utils"

export default function Repairs() {
  const [tasks, setTasks] = useState([])
  const [total, setTotal] = useState(0)
  const [offset, setOffset] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [query, setQuery] = useState("")
  const auth = JSON.parse(localStorage.getItem("dora.service"))
  const section = auth ? auth.section : ""
  const shift = auth ? auth.shift : ""
  const onPage = (event) => setOffset(event.first)
  const onFilter = (event) => event.filters.name
    ? setQuery(event.filters.name.value)
    : setQuery("")
  const history = useHistory()

  // grid state column
  const stateBodyTemplate = (rowData) => rowData.state
    ? <Badge severity={REPAIR_STATE_SEVERITY[rowData.state.id] || ""} value={rowData.state.name}/>
    : ""

  // grid state column
  const [state, setState] = useState("")
  const onStateChange = (e) => setState(e.value || "")
  const stateItemTemplate = (option) => option.label
  const stateOptions = [{
    value: 1,
    label: "Ano"
  }, {
    value: 2,
    label: "Ne"
  }, {
    value: 3,
    label: "Vyřazeno"
  },]
  const stateFilter = (
    <Dropdown value={state} options={stateOptions} onChange={onStateChange}
              itemTemplate={stateItemTemplate} placeholder={"Filtrovat dle stavu"}
              className={"p-column-filter"} showClear/>
  )
  const handleEdit = (id) => {
    history.push(`/service/repair/${id}`)
  }
  const controlBodyTemplate = (rowData) => (
    <>
      <Button className={"p-button-primary p-button-text p-button-sm"}
              icon={"pi pi-pencil"} title={"Upravit"} onClick={() => handleEdit(rowData.id)}/>
    </>
  )

  useEffect(() => {
    setIsLoading(true)
    let params = {
      limit: 50,
      offset: offset,
      section: section,
      shift: shift,
      query: query,
      state: state,
    }
    api.get(`service?${serialize(params)}`)
      .then(({data, headers}) => {
        setTotal(parseInt(headers["x-total-count"], 10))
        setTasks(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [offset, section, shift, query, state])

  return (
    <>
      <div className={"p-col-12"}>
        <Link to={"/service/repair"} className={"p-button p-button-success p-mr-3"}>Nová
          oprava</Link>
      </div>
      <div className={"p-col-12"}>
        <DataTable value={tasks} lazy paginator first={offset} totalRecords={total} rows={50}
                   onPage={onPage} onFilter={onFilter} loading={isLoading}
                   className={"p-datatable-striped p-datatable-sm"} emptyMessage={"Nic nenalezeno"}>
          <Column field={"name"} header={"Oprava/zapůjčení"} headerStyle={HEADER_STYLES} filter
                  filterPlaceholder={"Hledat podle názvu, zařízení, označení, servisu"}/>
          <Column field={"device"} header={"Zařízení"} headerStyle={HEADER_STYLES}/>
          <Column field={"label"} header={"Označení"} headerStyle={HEADER_STYLES}/>
          <Column field={"service"} header={"Servis"} headerStyle={HEADER_STYLES}/>
          <Column field={"departureAt"} header={"Odesláno"} headerStyle={HEADER_STYLES}/>
          <Column field={"state.name"} header={"Opraveno/vráceno"} headerStyle={HEADER_STYLES}
                  body={stateBodyTemplate} filter filterElement={stateFilter}/>
          <Column field={"acceptancedAt"} header={"Přijato"} headerStyle={HEADER_STYLES}/>
          <Column field={"control"} header={""} headerStyle={HEADER_STYLES}
                  body={controlBodyTemplate}/>
        </DataTable>
      </div>
    </>
  )
}
