import {useState} from "react"
import {Redirect, Route, Switch, useHistory} from "react-router-dom"
import Nav from "./component/Admin/Nav/Nav"
import Login from "./component/Admin/Login/Login"
import Tasks from "./component/Admin/Tasks/Tasks"
import TaskForm from "./component/Admin/Tasks/TaskForm"
import TaskDetail from "./component/Admin/Tasks/TaskDetail"
import History from "./component/Admin/History/History"
import Notifications from "./component/Admin/Notifications/Notifications"
import NotificationForm from "./component/Admin/Notifications/NotificationForm"
import Places from "./component/Admin/Places/Places"
import PlaceForm from "./component/Admin/Places/PlaceForm"
import Orders from "./component/Admin/Orders/Orders"
import OrderDetail from "./component/Admin/Orders/OrderDetail"
import Users from "./component/Admin/Users/Users"
import UserForm from "./component/Admin/Users/UserForm"
import Machines from "./component/Admin/Machines/Machines"
import MachineForm from "./component/Admin/Machines/MachineForm"
import MachineDetail from "./component/Admin/Machines/MachineDetail"
import Repairs from "./component/Admin/Repairs/Repairs"
import RepairForm from "./component/Admin/Repairs/RepairForm"
import api from "./api"
import ServiceLogin from "./component/Service/Login/Login"

export default function AdminApp() {
  let history = useHistory()
  let auth = JSON.parse(localStorage.getItem("dora.admin"))
  let [isLoggedIn, setIsLoggedIn] = useState(!!auth)

  if (auth) {
    api.setToken(auth.token)
  } else {
    history.push("/login")
  }

  const logInHandler = (loginData) => {
    api.post("auth", loginData)
      .then(({data}) => {
        localStorage.setItem("dora.admin", JSON.stringify(data))
        api.setToken(data.token)
        history.push("/")
        setIsLoggedIn(true)
      }).catch(() => alert("Špatná kombinace jména a hesla"))
  }

  const logOutHandler = () => {
    localStorage.removeItem("dora.admin")
    api.setToken("")
    history.push("/login")
    setIsLoggedIn(false)
  }

  return (
    <>
      {isLoggedIn && (
        <Nav auth={auth} logOutHandler={logOutHandler}/>
      )}

      <Switch>
        <Route path={"/"} exact>
          {isLoggedIn && <Redirect to={"/tasks"}/>}
        </Route>
        <Route path={"/login"} exact>
          <Login logInHandler={logInHandler}/>
        </Route>
        {/* tasks */}
        <Route path={"/task"} exact>
          <TaskForm/>
        </Route>
        <Route path={"/task/:id"} exact>
          <TaskForm/>
        </Route>
        <Route path={"/task/:id/detail"} exact>
          <TaskDetail/>
        </Route>
        <Route path={"/task/:id/copy"} exact>
          <TaskForm/>
        </Route>
        <Route path={"/tasks"} exact>
          <Tasks/>
        </Route>
        {/* history */}
        <Route path={"/history"} exact>
          <History/>
        </Route>
        {/* notifications */}
        <Route path={"/notification"} exact>
          <NotificationForm/>
        </Route>
        <Route path={"/notification/:id"} exact>
          <NotificationForm/>
        </Route>
        <Route path={"/notifications"} exact>
          <Notifications/>
        </Route>
        {/* places */}
        <Route path={"/place/:id"} exact>
          <PlaceForm/>
        </Route>
        <Route path={"/place"} exact>
          <PlaceForm/>
        </Route>
        <Route path={"/places"} exact>
          <Places/>
        </Route>
        {/* machines */}
        <Route path={"/machine/:id/detail"} exact>
          <MachineDetail/>
        </Route>
        <Route path={"/machine/:id"} exact>
          <MachineForm/>
        </Route>
        <Route path={"/machine"} exact>
          <MachineForm/>
        </Route>
        <Route path={"/machines"} exact>
          <Machines/>
        </Route>
        {/* orders */}
        <Route path={`/order/:id/detail`} exact>
          <OrderDetail/>
        </Route>
        <Route path={"/orders"} exact>
          <Orders/>
        </Route>
        {/* users */}
        <Route path={"/user/:id"} exact>
          <UserForm/>
        </Route>
        <Route path={"/user"} exact>
          <UserForm/>
        </Route>
        <Route path={"/users"} exact>
          <Users/>
        </Route>
        {/* repairs */}
        <Route path={"/repair/:id"} exact>
          <RepairForm/>
        </Route>
        <Route path={"/repair"} exact>
          <RepairForm/>
        </Route>
        <Route path={"/repairs"} exact>
          <Repairs/>
        </Route>

        {/* service */}
        <Route path={"/service"} exact>
          <ServiceLogin/>
        </Route>
      </Switch>
    </>
  )
}
