import {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import {Badge} from "primereact/badge"
import {Dropdown} from "primereact/dropdown"
import api from "../../../api"
import {HEADER_STYLES, ORDER_STATE_SEVERITY} from "../../common"
import {serialize} from "../../utils"

export default function Orders() {
  const [tasks, setTasks] = useState([])
  const [total, setTotal] = useState(0)
  const [offset, setOffset] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [query, setQuery] = useState("")
  const auth = JSON.parse(localStorage.getItem("dora.service"))
  const section = auth ? auth.section : ""

  // grid
  const onPage = (event) => setOffset(event.first)
  const onFilter = (event) => event.filters.title
    ? setQuery(event.filters.title.value)
    : setQuery("")

  // grid name column
  const titleBodyTemplate = (rowData) => (
    <Link to={`/service/order/${rowData.id}/detail`}
          className={"p-button p-button-link"}>{rowData.title}</Link>
  )

  // grid state column
  const stateBodyTemplate = (rowData) => rowData.state
    ? <Badge severity={ORDER_STATE_SEVERITY[rowData.state.id] || ""} value={rowData.state.name}/>
    : ""

  // grid machine column
  const machineBodyTemplate = (rowData) => (
    <span>{rowData.machine.name} {rowData.machine.place.name}</span>
  )

  // grid machine column
  const [machines, setMachines] = useState([])
  const [machine, setMachine] = useState("")
  const onMachineChange = (e) => setMachine(e.value || "")
  const machineItemTemplate = (option) => option.label
  const machineFilter = (
    <Dropdown value={machine} options={machines} onChange={onMachineChange}
              itemTemplate={machineItemTemplate} placeholder={"Filtrovat dle stroje"}
              className={"p-column-filter"} showClear/>
  )

  useEffect(() => {
    setIsLoading(true)
    let params = {
      limit: 50,
      offset: offset,
      section: section,
      query: query,
      machine: machine,
    }
    api.get(`order?${serialize(params)}`)
      .then(({data, headers}) => {
        setTotal(parseInt(headers["x-total-count"], 10))
        setTasks(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [offset, section, query, machine])

  useEffect(() => {
    api.get("machine/simple")
      .then(({data}) => {
        data = data.map(machine => Object.assign({}, machine, {
          label: machine.name,
          value: machine.id
        }))
        setMachines(data)
      })
  }, [])

  return (
    <>
      <div className={"p-col-12"}>
        <Link to={"/service/order"} className={"p-button p-button-success"}>Přidat objednávku</Link>
        <Link to={"/service/maintenances"} className={"p-button p-button-link"}>Přejít na
          knihu údržby</Link>
      </div>
      <div className={"p-col-12"}>
        <DataTable value={tasks} lazy paginator first={offset} totalRecords={total} rows={50}
                   onPage={onPage} onFilter={onFilter} loading={isLoading}
                   className={"p-datatable-striped p-datatable-sm"} emptyMessage={"Nic nenalezeno"}>
          <Column field={"title"} header={"Název"} headerStyle={HEADER_STYLES} filter
                  filterPlaceholder={"Filtrovat dle názvu"}
                  body={titleBodyTemplate}/>
          <Column field={"description"} header={"Popis"} headerStyle={HEADER_STYLES}/>
          <Column field={"machine"} header={"Stroj"} headerStyle={HEADER_STYLES}
                  body={machineBodyTemplate} filter filterElement={machineFilter}/>
          <Column field={"state.name"} header={"Stav"} headerStyle={HEADER_STYLES}
                  body={stateBodyTemplate}/>
        </DataTable>
      </div>
    </>
  )
}
