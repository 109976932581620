import {useEffect, useState} from "react"
import {useHistory, useParams} from "react-router-dom"
import {InputText} from "primereact/inputtext"
import {Button} from "primereact/button"
import {Dropdown} from "primereact/dropdown"
import {MultiSelect} from "primereact/multiselect"
import api from "../../../api"

export default function UserForm() {
  const [name, setName] = useState("")
  const [pass, setPass] = useState("")
  const [role, setRole] = useState("")
  const [machines, setMachines] = useState([])
  const [machineOptions, setMachineOptions] = useState([])
  const history = useHistory()
  const handleBack = () => history.push("/users")
  const {id} = useParams()
  const handleSubmit = (e) => {
    e.preventDefault()
    let data = {
      name,
      pass,
      role,
      machines,
    }

    if (pass === "") {
      delete data.pass
    }

    if (id) {
      api.put(`user/${id}`, data).then(handleBack)
    } else {
      api.post("user", data).then(handleBack)
    }
  }

  useEffect(() => {
    if (id) {
      api.get(`user/${id}`)
        .then(({data}) => {
          setName(data.name)
          setPass(data.pass)
          setRole(data.role)
          setMachines(data.machines)
        })
    }

    api.get("machine/simple")
      .then(({data}) => {
        data = data.map(item => Object.assign({}, item, {
          label: item.name,
          value: item.id,
        }))
        setMachineOptions(data)
      })
  }, [id])

  const roles = [{
    label: "admin",
    value: "admin",
  }, {
    label: "user (provoz, údržba)",
    value: "user",
  }]

  return (
    <div className={"p-lg-5"}>
      <form onSubmit={handleSubmit}>
        <div className={"p-fluid"}>
          <div className={"p-field"}>
            <label htmlFor={"name"}>*Jméno:</label>
            <InputText value={name} onChange={(e) => setName(e.target.value)} id={"name"} required/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"pass"}>
              {!id && "*"}
              Heslo:
            </label>
            <InputText value={pass} onChange={(e) => setPass(e.target.value)} id={"pass"}
                       required={!id}/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"role"}>*Role:</label>
            <Dropdown value={role} options={roles} onChange={(e) => setRole(e.value)} required/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"machine"}>*Stroj:</label>
            <MultiSelect value={machines} options={machineOptions}
                         onChange={(e) => setMachines(e.value)}
                         selectedItemsLabel={"{0} strojů vybráno"}/>
          </div>
        </div>

        <div className={"p-field"}>
          <Button label={"Zrušit"} type={"button"} className={"p-button-danger p-mr-3"}
                  onClick={handleBack}/>
          <Button label={"Uložit"} type={"submit"} className={"p-button-success p-mr-3"}/>
        </div>
      </form>
    </div>
  )
}
