import {useEffect, useState} from "react"
import {useHistory, useParams} from "react-router-dom"
import {Button} from "primereact/button"
import {Calendar} from "primereact/calendar"
import {InputTextarea} from "primereact/inputtextarea"
import {Badge} from "primereact/badge"
import moment from "moment"
import api from "../../../api"
import {TASK_STATE_SEVERITY} from "../../common"

export default function TaskComplete() {
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [machine, setMachine] = useState("")
  const [place, setPlace] = useState("")
  const [attachments, setAttachments] = useState([])
  const [state, setState] = useState({})
  const [expiredAt, setExpiredAt] = useState("")
  const [dateTimeFrom, setDateTimeFrom] = useState("")
  const [dateTimeTo, setDateTimeTo] = useState("")
  const [note, setNote] = useState("")

  const history = useHistory()
  const handleBack = () => history.push("/service/tasks")
  const {id} = useParams()

  const handleSubmit = (e) => {
    e.preventDefault()
    let data = {
      id: parseInt(id, 10),
      dateFrom: moment(dateTimeFrom).format("YYYY-MM-DD"),
      dateTo: moment(dateTimeTo).format("YYYY-MM-DD"),
      note,
      state: 2,
      timeFrom: moment(dateTimeFrom).format("HH:ss"),
      timeTo: moment(dateTimeTo).format("HH:ss"),
    }

    if (attachments.length > 0) {
      data.attachments = attachments
    }

    api.put(`task/${id}/result`, data)
      .then(() => handleBack())
  }

  const readFile = (e) => {
    e.preventDefault()
    let input = e.target
    if (input.files && input.files[0]) {
      let reader = new FileReader()
      reader.onload = (e) => {
        let isImage = /png|jpg|jpeg|gif/.test(input.files[0].name.toLowerCase())
        let newAttachments = attachments.map(item => Object.assign({}, item))
        newAttachments.push({
          name: input.files[0].name,
          content: btoa(e.target.result),
          is_image: isImage,
        })
        setAttachments(newAttachments)
      }
      reader.readAsDataURL(input.files[0]);
    }
  }

  const removeFile = (fileIndex) => {
    let newAttachments = attachments.filter((attachments, i) => i !== fileIndex)
    setAttachments(newAttachments)
  }

  useEffect(() => {
    api.get(`task/${id}`)
      .then(({data}) => {
        setName(data.name)
        setDescription(data.description)
        setMachine(data.machine)
        setPlace(data.place)
        setState(data.state)
        setExpiredAt(data.expiredAt)
      })
  }, [id])

  return (
    <form onSubmit={handleSubmit} className={"p-fluid"}>
      <div className={"p-grid p-p-3 p-jc-center"}>
        <div className={"p-col-12 p-lg-6 bg-white"}>
          <div className={"p-field"}>
            <strong>Název:</strong> <span>{name}</span>
          </div>

          <div className={"p-field"}>
            <strong>Stav:</strong> <Badge severity={TASK_STATE_SEVERITY[state.id]}
                                          value={state.name}/>
          </div>

          <div className={"p-field"}>
            <strong>Popis:</strong> <span>{description}</span>
          </div>

          <div className={"p-field"}>
            <strong>Termín dokončení:</strong> <span>{expiredAt}</span>
          </div>

          <div className={"p-field"}>
            <strong>Místo:</strong> <span>{place.name}</span>
          </div>

          <div className={"p-field"}>
            <strong>Stroj:</strong> <span>{machine.name}</span>
          </div>

          <div className={"p-field"}>
            <strong>Doba za kterou byl úkol dokončen:</strong>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"dateTimeFrom"}>Od:</label>
            <Calendar value={dateTimeFrom} onChange={(e) => setDateTimeFrom(e.value)}
                      id={"dateTimeFrom"} showTime required/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"dateTimeTo"}>Do:</label>
            <Calendar value={dateTimeTo} onChange={(e) => setDateTimeTo(e.value)} id={"dateTimeTo"}
                      showTime required/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"note"}>Poznámka:</label>
            <InputTextarea value={note} onChange={(e) => setNote(e.target.value)} id={"note"}/>
          </div>

          <div className={"p-field"}>
            <label>Přílohy:</label>
            {attachments.map((attachment, i) => (
              <div className={"p-grid"} key={attachment.content}>
                <div className={"p-col-5"}>{attachment.name}</div>
                <div className={"p-col-2"}>
                  <Button className={"p-button-danger p-button-icon-only"} icon={"pi pi-times"}
                          type={"button"} onClick={() => removeFile(i)}/>
                </div>
              </div>
            ))}

            <label className={"p-button p-button-primary"}>
              <input type={"file"} id={"file"} className={"p-d-none"} onChange={readFile}/>
              Vložit soubor
            </label>
          </div>
        </div>

        <div className={"p-col-12 p-lg-3"}>
          <Button label={"Dokončit"} type={"submit"} className={"p-button-success mr-3"}/>
        </div>
      </div>
    </form>
  )
}
