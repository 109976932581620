import {NavLink, useHistory} from "react-router-dom"
import logo from "./logo.png"
import styles from "../../nav.module.css"

export default function Nav(props) {
  const history = useHistory()

  return (
    <nav className={`${styles.Nav} p-d-flex p-jc-between`}>
      <span className={styles.Link}>
        <img src={logo} alt={"logo"} className={styles.Logo}/>
      </span>
      <ul className={styles.Menu}>
        <li className={styles.Item}>
          <span onClick={() => history.goBack()} className={`${styles.Link} ${styles.Pointer}`}>
            <i className={"pi pi-arrow-left"}/>
          </span>
        </li>
        <li className={styles.Item}>
          <NavLink to={"/tasks"} exact className={styles.Link}
                   activeClassName={styles.LinkActive}>úkoly</NavLink>
        </li>
        <li className={styles.Item}>
          <NavLink to={"/history"} className={styles.Link}
                   activeClassName={styles.LinkActive}>historie</NavLink>
        </li>
        <li className={styles.Item}>
          <NavLink to={"/notifications"} className={styles.Link}
                   activeClassName={styles.LinkActive}>notifikace</NavLink>
        </li>
        <li className={styles.Item}>
          <NavLink to={"/places"} className={styles.Link}
                   activeClassName={styles.LinkActive}>místa</NavLink>
        </li>
        <li className={styles.Item}>
          <NavLink to={"/machines"} className={styles.Link}
                   activeClassName={styles.LinkActive}>stroje/pracoviště</NavLink>
        </li>
        <li className={styles.Item}>
          <NavLink to={"/orders"} className={styles.Link}
                   activeClassName={styles.LinkActive}>objednávky</NavLink>
        </li>
        <li className={styles.Item}>
          <NavLink to={"/users"} className={styles.Link}
                   activeClassName={styles.LinkActive}>uživatelé</NavLink>
        </li>
        <li className={styles.Item}>
          <NavLink to={"/repairs"} className={styles.Link}
                   activeClassName={styles.LinkActive}>opravy a servis</NavLink>
        </li>
        <li className={styles.Item}>
          <span className={styles.Link}>
          {props.auth.name} (<span onClick={props.logOutHandler}
                                   className={styles.Pointer}>odhlásit</span>)
          </span>
        </li>
      </ul>
    </nav>
  )
}
