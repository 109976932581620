import {useEffect, useState} from "react"
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import {Dropdown} from "primereact/dropdown"
import {Badge} from "primereact/badge"
import api from "../../../api"
import {HEADER_STYLES, TASK_STATE, TASK_STATE_SEVERITY} from "../../common"
import {serialize} from "../../utils"

export default function History() {
  let [tasks, setTasks] = useState([])
  let [total, setTotal] = useState(0)
  let [offset, setOffset] = useState(0)
  let [isLoading, setIsLoading] = useState(false)
  const [query, setQuery] = useState("")

  // grid
  const onPage = (event) => setOffset(event.first)
  const onFilter = (event) => event.filters.title
    ? setQuery(event.filters.title.value)
    : setQuery("")

  // grid machine column
  const [machines, setMachines] = useState([])
  const [machine, setMachine] = useState("")
  const onMachineChange = (e) => setMachine(e.value || "")
  const machineItemTemplate = (option) => option.label
  const machineFilter = (
    <Dropdown value={machine} options={machines} onChange={onMachineChange}
              itemTemplate={machineItemTemplate} placeholder={"Filtrovat dle stroje"}
              className={"p-column-filter"} showClear/>
  )

  // grid section column
  const [sections, setSections] = useState([])
  const [section, setSection] = useState("")
  const onSectionChange = (e) => setSection(e.value || "")
  const sectionItemTemplate = (option) => option.label
  const sectionFilter = (
    <Dropdown value={section} options={sections} onChange={onSectionChange}
              itemTemplate={sectionItemTemplate} placeholder={"Filtrovat dle sekce"}
              className={"p-column-filter"} showClear/>
  )
  const sectionBodyTemplate = (rowData) => rowData.section.map(section => section.name).join(", ")

  // grid state column
  const [state, setState] = useState("")
  const onStateChange = (e) => setState(e.value || "")
  const stateItemTemplate = (option) => option.label
  const stateFilter = (
    <Dropdown value={state} options={TASK_STATE} onChange={onStateChange}
              itemTemplate={stateItemTemplate} placeholder={"Filtrovat dle stavu"}
              className={"p-column-filter"} showClear/>
  )
  const stateBodyTemplate = (rowData) => rowData.state
    ? <Badge severity={TASK_STATE_SEVERITY[rowData.state.id] || ""} value={rowData.state.name}/>
    : ""

  // grid shift column
  const [shifts, setShifts] = useState([])
  const [shift, setShift] = useState("")
  const onShiftChange = (e) => setShift(e.value || "")
  const shiftItemTemplate = (option) => option.label
  const shiftFilter = (
    <Dropdown value={shift} options={shifts} onChange={onShiftChange}
              itemTemplate={shiftItemTemplate} placeholder={"Filtrovat dle směny"}
              className={"p-column-filter"} showClear/>
  )

  useEffect(() => {
    setIsLoading(true)
    let params = {
      limit: 50,
      offset,
      query,
      machine,
      state,
      section,
      shift,
    }
    api.get(`history?${serialize(params)}`)
      .then(({data, headers}) => {
        setTotal(parseInt(headers["x-total-count"], 10))
        setTasks(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [offset, query, machine, state, section, shift])

  useEffect(() => {
    api.get("machine/simple")
      .then(({data}) => {
        data = data.map(machine => Object.assign({}, machine, {
          label: machine.name,
          value: machine.id
        }))
        setMachines(data)
      })

    api.get("section")
      .then(({data}) => {
        let sections = []
        let getSection = (section) => {
          sections.push(Object.assign({}, section, {
            label: section.name,
            value: section.id
          }))
        }
        data.forEach(section => {
          getSection(section)
          if (section.children) {
            section.children.forEach(child => getSection(child))
          }
        })
        setSections(sections)
      })

    api.get("shift")
      .then(({data}) => {
        data = data.map(shift => Object.assign({}, shift, {
          label: shift.name,
          value: shift.id
        }))
        setShifts(data)
      })
  }, [])

  return (
    <div className={"p-col-12"}>
      <DataTable value={tasks} lazy paginator first={offset} totalRecords={total} rows={50}
                 onPage={onPage} onFilter={onFilter} loading={isLoading}
                 className={"p-datatable-striped p-datatable-sm"}
                 emptyMessage={"Nic nenalezeno"}>
        <Column field={"title"} header={"Název"} filter filterPlaceholder={"Filtrovat dle názvu"}
                headerStyle={HEADER_STYLES}/>
        <Column field={"machine.name"} header={"Stroj"} filter filterElement={machineFilter}
                headerStyle={HEADER_STYLES}/>
        <Column field={"state.name"} header={"Stav"} filter filterElement={stateFilter}
                body={stateBodyTemplate} headerStyle={HEADER_STYLES}/>
        <Column field={"section.name"} header={"Sekce"} filter filterElement={sectionFilter}
                headerStyle={HEADER_STYLES} body={sectionBodyTemplate}/>
        <Column field={"shift.name"} header={"Směna"} filter filterElement={shiftFilter}
                headerStyle={HEADER_STYLES}/>
        <Column field={"updateddAt"} header={"Změněno"} headerStyle={HEADER_STYLES}/>
        <Column field={"updatedBy.name"} header={"Změnil"} headerStyle={HEADER_STYLES}/>
      </DataTable>
    </div>
  )
}
