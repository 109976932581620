import {useEffect, useState} from "react"
import {useHistory, useParams} from "react-router-dom"
import {InputText} from "primereact/inputtext"
import {Dropdown} from "primereact/dropdown"
import {Button} from "primereact/button"
import api from "../../../api"
import {ATTACHMENT_TYPE} from "../../common"

export default function MachineForm() {
  const [name, setName] = useState("")
  const [places, setPlaces] = useState([])
  const [place, setPlace] = useState("")
  const [attachments, setAttachments] = useState([])
  const history = useHistory()
  const handleBack = () => history.push("/machines")
  const {id} = useParams()
  const handleSubmit = (e) => {
    e.preventDefault()
    let data = {
      name,
      place,
      description: "",
      parameters: "",
    }

    if (attachments.length) {
      data.attachments = attachments
    }

    if (id) {
      api.put(`machine/${id}`, data).then(handleBack)
    } else {
      api.post("machine", data).then(handleBack)
    }
  }

  useEffect(() => {
    if (id) {
      api.get(`machine/${id}`)
        .then(({data}) => {
          setName(data.name)
          setPlace(data.place.id)
          setAttachments(data.attachments)
        })
    }

    api.get("place")
      .then(({data}) => {
        data = data.map(item => Object.assign({}, item, {
          label: item.name,
          value: item.id,
        }))
        setPlaces(data)
      })
  }, [id])

  const readFile = (e) => {
    e.preventDefault()
    let input = e.target
    if (input.files && input.files[0]) {
      let reader = new FileReader()
      reader.onload = (e) => {
        let isImage = /png|jpg|jpeg|gif/.test(input.files[0].name.toLowerCase())
        let newAttachments = attachments.map(item => Object.assign({}, item))
        newAttachments.push({
          name: input.files[0].name,
          content: btoa(e.target.result),
          is_image: isImage,
          type: 1,
        })
        setAttachments(newAttachments)
      }
      reader.readAsDataURL(input.files[0]);
    }
  }

  const handleFileType = (fileIndex, type) => {
    let newAttachments = attachments.map((attachment, i) => {
      if (i === fileIndex) {
        return Object.assign({}, attachment, {type})
      }
      return Object.assign({}, attachment)
    })
    setAttachments(newAttachments)
  }

  const removeFile = (fileIndex) => {
    let newAttachments = attachments.filter((attachments, i) => i !== fileIndex)
    setAttachments(newAttachments)
  }

  return (
    <div className={"p-lg-5"}>
      <form onSubmit={handleSubmit}>
        <div className={"p-fluid"}>
          <div className={"p-field"}>
            <label htmlFor={"name"}>*Jméno:</label>
            <InputText value={name} onChange={(e) => setName(e.target.value)} id={"name"} required/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"place"}>*Místo:</label>
            <Dropdown value={place} options={places} onChange={(e) => setPlace(e.target.value)}
                      id={"place"} required/>
          </div>

          <div className={"p-field"}>
            <label>Přílohy:</label>
            {attachments.map((attachment, i) => (
              <div className={"p-grid"} key={attachment.content}>
                <div className={"p-col-5"}>{attachment.name}</div>
                <div className={"p-col-5"}>
                  <Dropdown value={attachment.type} options={ATTACHMENT_TYPE}
                            placeholder={"Typ"} onChange={(e) => handleFileType(i, e.value)}/>
                </div>
                <div className={"p-col-2"}>
                  <Button className={"p-button-danger p-button-icon-only"} icon={"pi pi-times"}
                          type={"button"} onClick={() => removeFile(i)}/>
                </div>
              </div>
            ))}

            <label className={"p-button p-button-primary"}>
              <input type={"file"} id={"file"} className={"p-d-none"} onChange={readFile}/>
              Vložit soubor
            </label>
          </div>
        </div>

        <div className={"p-field"}>
          <Button label={"Zrušit"} type={"button"} className={"p-button-danger p-mr-3"}
                  onClick={handleBack}/>
          <Button label={"Uložit"} type={"submit"} className={"p-button-success p-mr-3"}/>
        </div>
      </form>
    </div>
  )
}
