import {useEffect, useState} from "react"
import {Link, useHistory} from "react-router-dom"
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import {Badge} from "primereact/badge"
import {Button} from "primereact/button"
import api from "../../../api"
import {HEADER_STYLES, REPAIR_STATE_SEVERITY} from "../../common"

export default function Repairs() {
  const [tasks, setTasks] = useState([])
  const [total, setTotal] = useState(0)
  const [offset, setOffset] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [XLS, setXLS] = useState("")
  const onPage = (event) => setOffset(event.first)
  const history = useHistory()

  // grid state column
  const stateBodyTemplate = (rowData) => rowData.state
    ? <Badge severity={REPAIR_STATE_SEVERITY[rowData.state.id] || ""} value={rowData.state.name}/>
    : ""

  // grid control column
  const handleEdit = (id) => {
    history.push(`repair/${id}`)
  }
  const handleDelete = (id) => {
    api.delete(`service/${id}`)
      .then(() => setIsUpdated(true))
  }
  const controlBodyTemplate = (rowData) => (
    <>
      <Button className={"p-button-primary p-button-text p-button-sm"}
              icon={"pi pi-pencil"} title={"Upravit"} onClick={() => handleEdit(rowData.id)}/>
      <Button className={"p-button-danger p-button-text p-button-sm"}
              icon={"pi pi-times"} title={"Smazat"} onClick={() => handleDelete(rowData.id)}/>
    </>
  )

  useEffect(() => {
    setIsLoading(true)
    api.get(`service?limit=50&offset=${offset}`)
      .then(({data, headers}) => {
        setTotal(parseInt(headers["x-total-count"], 10))
        setTasks(data)
      })
      .finally(() => {
        setIsLoading(false)
        setIsUpdated(false)
      })
  }, [offset, isUpdated])

  useEffect(() => {
    api.get("service/xls")
      .then(({data}) => setXLS(data.content))
  }, [])

  return (
    <>
      <div className={"p-col-12"}>
        <Link to={"/repair"} className={"p-button p-button-success p-mr-3"}>Nová oprava</Link>
        <Link to={`data:application/vnd.ms-excel;base64,${XLS}`} download="export.xls"
              className={"p-button p-button-link"}>Stáhnout jako XLS soubor</Link>
      </div>
      <div className={"p-col-12"}>
        <DataTable value={tasks} lazy paginator first={offset} totalRecords={total} rows={50}
                   onPage={onPage} loading={isLoading}
                   className={"p-datatable-striped p-datatable-sm"}
                   emptyMessage={"Nic nenalezeno"}>
          <Column field={"name"} header={"Oprava/zapůjčení"} headerStyle={HEADER_STYLES}/>
          <Column field={"device"} header={"Zařízení"} headerStyle={HEADER_STYLES}/>
          <Column field={"label"} header={"Označení"} headerStyle={HEADER_STYLES}/>
          <Column field={"service"} header={"Servis"} headerStyle={HEADER_STYLES}/>
          <Column field={"departureAt"} header={"Odesláno"} headerStyle={HEADER_STYLES}/>
          <Column field={"state.name"} header={"Opraveno/vráceno"} headerStyle={HEADER_STYLES}
                  body={stateBodyTemplate}/>
          <Column field={"acceptancedAt"} header={"Přijato"} headerStyle={HEADER_STYLES}/>
          <Column field={"control"} header={""} headerStyle={HEADER_STYLES}
                  body={controlBodyTemplate}/>
        </DataTable>
      </div>
    </>
  )
}
