import {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import api from "../../../api"
import {ATTACHMENT_TYPE} from "../../common"

export default function MachineDetail() {
  const [name, setName] = useState("")
  const [place, setPlace] = useState("")
  const [numbers, setNumbers] = useState({})
  const [attachments, setAttachments] = useState([])
  const {id} = useParams()
  const getAttachmentType = (id) => ATTACHMENT_TYPE.find(attachmentType => attachmentType.value === id).label

  useEffect(() => {
    api.get(`machine/${id}`)
      .then(({data}) => {
        setName(data.name)
        setPlace(data.place.name)
        setNumbers(data.numbers)
        setAttachments(data.attachments)
      })
  }, [id])

  return (
    <div className={"p-grid p-p-3 p-jc-center"}>
      <div className={"p-col-12 p-lg-9 bg-white"}>
        <div className={"p-field"}>
          <strong>Název:</strong> <span>{name}</span>
        </div>

        <div className={"p-field"}>
          <strong>Místo:</strong> <span>{place}</span>
        </div>

        <div className={"p-field"}>
          <strong>Statistiky:</strong>
          <ul>
            <li>Úkoly dnes: {numbers.task_today}</li>
            <li>Unikátní úkoly: {numbers.task_unique_prevention}</li>
            <li>Prevence: {numbers.task_prevention}</li>
            <li>Po termínu: {numbers.task_expired}</li>
            <li>Dokončeno úkolů:{numbers.task_done}</li>
            <li>Dokončeno oprav: {numbers.book_repair}</li>
            <li>Běžná údržba: {numbers.book_maintenance}</li>
          </ul>
        </div>

        <div className={"p-field"}>
          <strong>Přílohy:</strong>
          <ul>
            {attachments.map((attachment) => (
              <li key={attachment.content}>
                <a href={attachment.url} target={"_blank"} rel={"noreferrer"}>{attachment.name}</a>
                <span> - {getAttachmentType(attachment.type)}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
