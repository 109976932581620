import {useState} from "react"
import {InputText} from "primereact/inputtext"
import {Button} from "primereact/button"
import styles from "./styles.module.css"

export default function Login(props) {
  let [user, setUser] = useState("")
  let [pass, setPass] = useState("")

  let handleSubmit = (e) => {
    e.preventDefault()
    props.logInHandler({user, pass})
  }

  return (
    <div className={"p-col-12 p-lg-4 p-lg-offset-4"}>
      <form onSubmit={handleSubmit} className={styles.Form}>
        <div className={"p-field"}>
          <label htmlFor={"user"}>Jméno:</label>
          <InputText value={user} onChange={(e) => setUser(e.target.value)} id={"user"}
                     type={"text"} placeholder={"Jméno"} required={true} className={styles.Input}
                     data-cy={"user"}/>
        </div>

        <div className={"p-field"}>
          <label htmlFor={"pass"}>Heslo:</label>
          <InputText value={pass} onChange={(e) => setPass(e.target.value)} id={"pass"}
                     type={"password"} placeholder={"Heslo"} required={true}
                     className={styles.Input} data-cy={"pass"}/>
        </div>

        <Button label={"Přihlásit"} type={"submit"}
                className={`p-button-success ${styles.Submit}`} data-cy={"submit"}/>
      </form>
    </div>
  )
}
