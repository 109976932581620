import {useEffect, useState} from "react"
import {Card} from "primereact/card"
import {Button} from "primereact/button"
import {InputText} from "primereact/inputtext"
import api from "../../../api"
import styles from "./styles.module.css"

export default function Login(props) {
  const [sections, setSections] = useState([])
  const [section, setSection] = useState("")
  const [shifts, setShifts] = useState([])
  const [shift, setShift] = useState("")
  const [user, setUser] = useState("")
  const [pass, setPass] = useState("")
  const handleSectionChange = (id) => {
    setSection(id)
    if (id === 1) {
      setShift(1) // there is no shift for section 1
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    props.logInHandler({user, pass, shift, section})
  }

  useEffect(() => {
    api.get("section")
      .then(({data}) => {
        setSections(data)
      })
    api.get("shift")
      .then(({data}) => {
        setShifts(data)
      })
  }, [])

  return (
    <div className={"p-grid p-p-3 p-jc-center p-text-center"}>
      {/* step 1 - section */}
      {section === "" && (
        <>
          <h1 className={"p-col-12"}>SEKCE</h1>

          {sections.map(sec => (
            <div className={"p-col-3 p-text-center"} key={sec.id}>
              {sec.children
                ? sec.children.map(child => (
                  <div key={child.id} onClick={() => handleSectionChange(child.id)}>
                    <Card className={styles.Card}>{sec.name} - {child.name}</Card>
                  </div>
                ))
                : (
                  <div onClick={() => handleSectionChange(sec.id)}>
                    <Card className={styles.Card}>{sec.name}</Card>
                  </div>
                )
              }
            </div>
          ))}
        </>
      )}

      {/* step 2 - shift */}
      {section !== "" && shift === "" && (
        <>
          <h1 className={"p-col-12"}>Skupina</h1>
          {shifts.map(shift => (
            <div className={"p-col-3 p-text-center"} key={shift.id}
                 onClick={() => setShift(shift.id)}>
              <Card className={styles.Card}>{shift.name}</Card>
            </div>
          ))}
        </>
      )}

      {/* step 3 - login */}
      {section !== "" && shift !== "" && (
        <div className={"p-col-12 p-lg-4 p-fluid p-text-left"}>
          <form onSubmit={handleSubmit} className={styles.Form}>
            <div className={"p-field"}>
              <label htmlFor={"user"}>Jméno:</label>
              <InputText value={user} onChange={(e) => setUser(e.target.value)} id={"user"}
                         type={"text"} placeholder={"Jméno"} required={true}
                         className={styles.Input}
                         data-cy={"user"}/>
            </div>

            <div className={"p-field"}>
              <label htmlFor={"pass"}>Heslo:</label>
              <InputText value={pass} onChange={(e) => setPass(e.target.value)} id={"pass"}
                         type={"password"} placeholder={"Heslo"} required={true}
                         className={styles.Input} data-cy={"pass"}/>
            </div>

            <Button label={"Přihlásit"} type={"submit"}
                    className={`p-button-success ${styles.Submit}`} data-cy={"submit"}/>
          </form>
        </div>
      )}
    </div>
  )
}
