import {useEffect, useState} from "react"
import {Link, useHistory} from "react-router-dom"
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import {Button} from "primereact/button"
import api from "../../../api"
import {HEADER_STYLES} from "../../common"

export default function Places() {
  const [tasks, setTasks] = useState([])
  const [total, setTotal] = useState(0)
  const [offset, setOffset] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)

  const history = useHistory()

  // grid
  const onPage = (event) => setOffset(event.first)

  // grid control column
  const handleEdit = (id) => {
    history.push(`place/${id}`)
  }
  const handleDelete = (id) => {
    api.delete(`place/${id}`)
      .then(() => setIsUpdated(true))
  }
  const controlBodyTemplate = (rowData) => (
    <>
      <Button className={"p-button-primary p-button-text p-button-sm"}
              icon={"pi pi-pencil"} title={"Upravit"} onClick={() => handleEdit(rowData.id)}/>
      <Button className={"p-button-danger p-button-text p-button-sm"}
              icon={"pi pi-times"} title={"Smazat"} onClick={() => handleDelete(rowData.id)}/>
    </>
  )

  useEffect(() => {
    setIsLoading(true)
    api.get(`place?limit=50&offset=${offset}`)
      .then(({data, headers}) => {
        setTotal(parseInt(headers["x-total-count"], 10))
        setTasks(data)
      })
      .finally(() => {
        setIsLoading(false)
        setIsUpdated(false)
      })
  }, [offset, isUpdated])

  return (
    <>
      <div className={"p-col-12"}>
        <Link to={"/place"} className={"p-button p-button-success"}>Nové místo</Link>
      </div>
      <div className={"p-col-12"}>
        <DataTable value={tasks} lazy paginator first={offset} totalRecords={total} rows={50}
                   onPage={onPage} loading={isLoading}
                   className={"p-datatable-striped p-datatable-sm"} emptyMessage={"Nic nenalezeno"}>
          <Column field={"name"} header={"Název"} headerStyle={HEADER_STYLES}/>
          <Column field={"control"} header={""} headerStyle={HEADER_STYLES}
                  body={controlBodyTemplate}/>
        </DataTable>
      </div>
    </>
  )
}
