import {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import api from "../../../api"

export default function MaintenanceDetail() {
  const [title, setTitle] = useState("")
  const [activity, setActivity] = useState("")
  const [type, setType] = useState("")
  const [timeFrom, setTomeFrom] = useState("")
  const [timeTo, setTimeTo] = useState("")
  const [machine, setMachine] = useState("")
  const [attachments, setAttachments] = useState([])
  const {id} = useParams()

  useEffect(() => {
    api.get(`maintenanceBook/${id}`)
      .then(({data}) => {
        setTitle(data.title)
        setActivity(data.activity)
        setType(data.type.name)
        setTomeFrom(data.timeFrom)
        setTimeTo(data.timeTo)
        setMachine(data.machine.name)
        setAttachments(data.attachments)
      })
  }, [id])

  return (
    <div className={"p-grid p-p-3 p-jc-center"}>
      <div className={"p-col-12 p-lg-9 bg-white"}>
        <div className={"p-field"}>
          <strong>Název:</strong> <span>{title}</span>
        </div>


        <div className={"p-field"}>
          <strong>Popis:</strong> <span>{activity}</span>
        </div>

        <div className={"p-field"}>
          <strong>Typ:</strong> <span>{type}</span>
        </div>

        <div className={"p-field"}>
          <strong>Stroj:</strong> <span>{machine}</span>
        </div>

        <div className={"p-field"}>
          <strong>Od:</strong> <span>{timeFrom}</span>
        </div>

        <div className={"p-field"}>
          <strong>Do:</strong> <span>{timeTo}</span>
        </div>

        <div className={"p-field"}>
          <strong>Přílohy:</strong>
          <ul>
            {attachments.map((attachment) => (
              <li key={attachment.content}>
                <a href={attachment.url} target={"_blank"} rel={"noreferrer"}>{attachment.name}</a>
                {attachment.note && (<span> - {attachment.note}</span>)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
