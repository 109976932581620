import {useEffect, useState} from "react"
import {useHistory, useParams} from "react-router-dom"
import {InputText} from "primereact/inputtext"
import {Calendar} from "primereact/calendar"
import {Button} from "primereact/button"
import {Dropdown} from "primereact/dropdown"
import moment from "moment"
import api from "../../../api"

export default function RepairForm() {
  const [name, setName] = useState("")
  const [device, setDevice] = useState("")
  const [label, setLabel] = useState("")
  const [service, setService] = useState("")
  const [departuredAt, setDeparturedAt] = useState("")
  const [acceptancedAt, setAcceptancedAt] = useState("")
  const [state, setState] = useState("")
  const history = useHistory()
  const handleBack = () => history.push("/repairs")
  const {id} = useParams()
  const repairState = [{
    value: 1,
    label: "Ano",
  }, {
    value: 2,
    label: "Ne",
  }, {
    value: 3,
    label: "Vyřazeno",
  }]

  const handleSubmit = (e) => {
    e.preventDefault()
    let data = {
      name,
      device,
      label,
      service,
      departuredAt: moment(departuredAt).format("YYYY-MM-DD HH:mm"),
      acceptancedAt: moment(acceptancedAt).format("YYYY-MM-DD HH:mm"),
      state,
    }

    if (id) {
      api.put(`service/${id}`, data).then(handleBack)
    } else {
      api.post("service", data).then(handleBack)
    }
  }

  useEffect(() => {
    if (id) {
      api.get(`service/${id}`)
        .then(({data}) => {
          setName(data.name)
          setDevice(data.device)
          setLabel(data.label)
          setService(data.service)
          setDeparturedAt(moment(data.departuredAt).toDate())
          setAcceptancedAt(moment(data.acceptancedAt).toDate())
          setState(data.state.id)
        })
    }
  }, [id])

  return (
    <div className={"p-lg-5"}>
      <form onSubmit={handleSubmit}>
        <div className={"p-fluid"}>
          <div className={"p-field"}>
            <label htmlFor={"name"}>*Název:</label>
            <InputText value={name} onChange={(e) => setName(e.target.value)} id={"name"} required/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"device"}>Zařízení:</label>
            <InputText value={device} onChange={(e) => setDevice(e.target.value)} id={"device"}/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"label"}>Označení dílu:</label>
            <InputText value={label} onChange={(e) => setLabel(e.target.value)} id={"label"}/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"service"}>Servis:</label>
            <InputText value={service} onChange={(e) => setService(e.target.value)} id={"label"}/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"departuredAt"}>Datum odeslání:</label>
            <Calendar value={departuredAt} onChange={(e) => setDeparturedAt(e.value)}
                      id={"departuredAt"} showTime/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"acceptancedAt"}>Datum přijetí:</label>
            <Calendar value={acceptancedAt} onChange={(e) => setAcceptancedAt(e.value)}
                      id={"acceptancedAt"} showTime/>
          </div>

          <div className={"p-field"}>
            <label htmlFor={"state"}>Opraveno:</label>
            <Dropdown value={state} options={repairState} onChange={(e) => setState(e.value)}
                      id={"state"}/>
          </div>
        </div>

        <div className={"p-field"}>
          <Button label={"Zrušit"} type={"button"} className={"p-button-danger p-mr-3"}
                  onClick={handleBack}/>
          <Button label={"Uložit"} type={"submit"} className={"p-button-success p-mr-3"}/>
        </div>
      </form>
    </div>
  )
}
