import {useEffect, useState} from "react"
import {useHistory, useParams} from "react-router-dom"
import {InputText} from "primereact/inputtext"
import {Button} from "primereact/button"
import api from "../../../api"

export default function PlaceForm() {
  const [name, setName] = useState("")
  const history = useHistory()
  const handleBack = () => history.push("/places")
  const {id} = useParams()
  const handleSubmit = (e) => {
    e.preventDefault()
    if (id) {
      api.put(`place/${id}`, {name}).then(handleBack)
    } else {
      api.post("place", {name}).then(handleBack)
    }
  }

  useEffect(() => {
    if (id) {
      api.get(`place/${id}`)
        .then(({data}) => {
          setName(data.name)
        })
    }
  }, [id])

  return (
    <div className={"p-lg-5"}>
      <form onSubmit={handleSubmit}>
        <div className={"p-fluid"}>
          <div className={"p-field"}>
            <label htmlFor={"name"}>Název:</label>
            <InputText value={name} onChange={(e) => setName(e.target.value)} id={"name"} required/>
          </div>
        </div>

        <div className={"p-field"}>
          <Button label={"Zrušit"} type={"button"} className={"p-button-danger p-mr-3"}
                  onClick={handleBack}/>
          <Button label={"Uložit"} type={"submit"} className={"p-button-success p-mr-3"}/>
        </div>
      </form>
    </div>
  )
}
